<template>
  <div class="content">
    <PageHeader title="Reclame Aqui" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-body block-el" v-if="fetched">
          <div class="form-body" style="padding: 10px;" v-if="account.deleted">
            <div class="form-group row">
              <label class="control-label text-right col-md-4 text-left-m"
                ></label
              >
              <div class="col-md-4">
                <h3>Solicitar ativação</h3>
                <p class="alert alert-info">Solicite ativação do Reclame Aqui usando o usuário e senha fornecidos</p>
              </div>
            </div>
            <div class="form-group row">
              <label class="control-label text-right col-md-4 text-left-m"
                >Usuário</label
              >
              <div class="col-md-4">
                <input
                  v-model="form.user_name"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="control-label text-right col-md-4 text-left-m"
                >Senha</label
              >
              <div class="col-md-4">
                <input
                  v-model="form.pass"
                  type="password"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="control-label text-right col-md-4 text-left-m"
                ></label
              >
              <div class="col-md-4">
                <button
                  :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
                  :disabled="isSending"
                  @click="createAccount"
                  type="button"
                  class="btn btn-primary"
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="sc-ccbnFN jJKZnq">
              <ul class="sc-fNHLbd kjZuvk">
                <li class="sc-jMtzgO" :class="{'dkprfy' : index == 0, 'drnbYh': index != 0}"><button type="button" class="sc-lhLRcH jMoMhH" @click="index = 0">6 meses</button></li>
                <li class="sc-jMtzgO" :class="{'dkprfy' : index == 1, 'drnbYh': index != 1}"><button type="button" class="sc-lhLRcH jMoMhH" @click="index = 1">12 meses</button></li>
                <li class="sc-jMtzgO" :class="{'dkprfy' : index == 2, 'drnbYh': index != 2}"><button type="button" class="sc-lhLRcH jMoMhH" @click="index = 2">2021</button></li>
                <li class="sc-jMtzgO" :class="{'dkprfy' : index == 3, 'drnbYh': index != 3}"><button type="button" class="sc-lhLRcH jMoMhH" @click="index = 3">2020</button></li>
                <li class="sc-jMtzgO" :class="{'dkprfy' : index == 4, 'drnbYh': index != 4}"><button type="button" class="sc-lhLRcH jMoMhH" @click="index = 4">Geral</button></li>
              </ul>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="sc-10br7v8-8 tRZRv">
                  <div class="stats">
                    <div size="60" class="sc-1qp09zw-0 diWwYI">
                    </div>
                    <div class="info">
                      <span class="description">{{this.reputation[this.index].reputation._name}}</span>
                      <span class="score"><b>{{this.reputation[this.index].finalGrade}}</b>/10</span>
                      <span class="hide">22/07/2019 - 21/07/2022</span>
                    </div>
                  </div>
                  <div class="sc-10br7v8-6 bnPPJr">
                    <a href="#">
                      <div class="short-ammount">
                        <span>Reclamações</span>
                        <br>
                        <div class="stats">
                          <i class="fas fa-bullhorn MuiSvgIcon-root"></i>
                          <b>{{this.reputation[this.index].complaintsCount}}</b>
                        </div>
                      </div>
                    </a>
                    <div class="bar"></div>
                    <a href="#">
                      <div class="short-ammount">
                        <span>Respondidas</span>
                        <br>
                        <div class="stats">
                          <i class="fas fa-check MuiSvgIcon-root"></i>
                          <b>{{this.reputation[this.index].answeredComplaintsCount}}</b>
                        </div>
                      </div>
                    </a>
                    <div class="bar"></div>
                    <a href="#">
                      <div class="short-ammount">
                        <span>Não respondidas</span>
                        <br>
                        <div class="stats">
                          <i class="fas fa-times MuiSvgIcon-root"></i>
                          <b>{{this.reputation[this.index].unansweredComplaintsCount}}</b>
                        </div>
                      </div>
                    </a>
                    <div class="bar"></div>
                    <a href="#">
                      <div class="short-ammount">
                        <span>Avaliadas</span>
                        <br>
                        <div class="stats">
                          <i class="fas fa-star MuiSvgIcon-root"></i>
                          <b>{{this.reputation[this.index].assessedComplaints}}</b>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="sc-1yrkgba-0 cDiwRh false">
                  <div class="sc-10br7v8-1 fqzUjM">
                      <p>Reclamações respondidas</p>
                      <div title="100%" class="sc-9xbj9-0 chyWjA bar-container">
                          <div class="sc-9xbj9-2 gArEkU"><div color="" class="sc-9xbj9-1 jAyKtJ-progress" :style="`width: ${this.reputation[this.index].responseIndex}px;`"></div></div>
                          <span class="label">{{this.reputation[this.index].responseIndex}}%</span>
                      </div>
                      <p>Voltariam a fazer negócio</p>
                      <div title="86.3%" class="sc-9xbj9-0 chyWjA bar-container">
                          <div class="sc-9xbj9-2 gArEkU"><div color="" class="sc-9xbj9-1 jAyKtJ-progress" :style="`width: ${this.reputation[this.index].wouldDoBusinessAgainPercentage}px;`"></div></div>
                          <span class="label">{{this.reputation[this.index].wouldDoBusinessAgainPercentage}}%</span>
                      </div>
                      <p>Índice de solução</p>
                      <div title="84.3%" class="sc-9xbj9-0 chyWjA bar-container">
                          <div class="sc-9xbj9-2 gArEkU"><div color="" class="sc-9xbj9-1 jAyKtJ-progress" :style="`width: ${this.reputation[this.index].solutionsPercentage}px;`"></div></div>
                          <span class="label">{{this.reputation[this.index].solutionsPercentage}}%</span>
                      </div>
                      <p>Nota do consumidor</p>
                      <div title="8.06" class="sc-9xbj9-0 chyWjA bar-container">
                          <div class="sc-9xbj9-2 gArEkU"><div color="" class="sc-9xbj9-1 jAyKtJ-progress" :style="`width: ${this.reputation[this.index].avgGrade}px;`"></div></div>
                          <span class="label">{{this.reputation[this.index].avgGrade}}</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
          </div>
        </div>
        <div v-else class="static qt-block-ui" style="padding: 120px" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import RAService from '@/services/ra.service';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
  },
  created() {
    this.getAccount();
    this.getReputation();
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      index: 0,
      form: {},
      reputation: [
        { reputation: {} },
      ],
      account: {
        deleted: true,
      },
    };
  },
  methods: {
    changeTab(index) {
      this.index = index;
    },
    createAccount() {
      this.isSending = true;
      RAService.createAccount(this.form).then(
        (response) => {
          console.log(response);
          this.isSending = false;
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Conta ativada com sucesso!',
            type: 'success',
          });
          this.getAccount();
        },
        (error) => {
          this.content = error;
        },
      );
    },
    getAccount() {
      RAService.checkAccount().then(
        (response) => {
          this.account = response;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    getReputation() {
      RAService.getReputation().then(
        (response) => {
          console.log('Reputation ra:');
          console.log(response);
          this.reputation = response;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style lang="scss">
.tRZRv {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.tRZRv .stats {
  display: flex;
  flex-direction: row;
}
.diWwYI {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  width: fit-content;
}
.diWwYI img {
  width: 60px;
  height: 60px;
}
.tRZRv .stats .info {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}
.tRZRv .stats .info .description {
  font-size: 14px;
  font-weight: bold;
}
.tRZRv .stats .info .score {
  display: flex;
  -moz-box-align: center;
  align-items: center;
}
.tRZRv .stats .info .score b {
  font-size: 24px;
  font-weight: bold;
  margin-right: 3px;
}
.eVTnLD {
  color: rgb(0, 0, 0);
}
bnPPJr {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;
  padding: 8px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-around;
}
.bnPPJr a, .bnPPJr a:hover {
  text-decoration: none;
  color: inherit;
}
.bnPPJr .short-ammount {
  padding: 5px;
}
.bnPPJr .short-ammount span {
  font-size: 12px;
  color: rgb(75, 89, 99);
}
.bnPPJr .short-ammount .stats {
  display: flex;
  flex-direction: row;
  -moz-box-align: center;
  align-items: center;
}
.bnPPJr .short-ammount .stats svg {
  fill: rgb(75, 89, 99);
  width: 18px;
  height: 18px;
}
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -moz-user-select: none;
  color: #617182 !important;
}
.MuiSvgIcon-root {
  font-size: 16px;
}
.bnPPJr .short-ammount .stats b {
  padding-left: 8px;
  font-size: 16px;
  font-weight: 600;
}
.bnPPJr .bar {
  height: 50px;
  width: 1px;
  background-color: rgb(225, 227, 229);
}
.bnPPJr a, .bnPPJr a:hover {
  text-decoration: none;
  color: inherit;
}
.bnPPJr .short-ammount {
  padding: 5px;
}
.bnPPJr .short-ammount span {
  font-size: 12px;
  color: rgb(75, 89, 99);
}
.bnPPJr {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;
  padding: 8px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-around;
}
.cDiwRh {
  transition: max-height 1s ease-in-out 0s;
  max-height: 25000px;
  overflow: hidden;
}
.fqzUjM {
  padding: 20px 20px 0px;
}
.fqzUjM p {
  margin: 0px 0px 3px;
}
.chyWjA {
  -moz-box-align: center;
  align-items: center;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.gArEkU {
  width: 100%;
  background: rgb(242, 242, 248) none repeat scroll 0% 0%;
  border-radius: 20px;
  height: 13px;
  margin: 0px 8px 0px 0px;
  overflow: hidden;
}
.jAyKtJ-progress {
  border-radius: inherit;
  height: 12px;
  background: #752de6;;
}
.chyWjA span.label {
  min-width: 50px;
}
.chyWjA span {
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0em;
  text-align: right;
  font-weight: 600;
  line-height: 22px;
  color: rgb(0, 0, 0);
}
.jJKZnq {
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  -moz-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(236, 236, 239);
  padding-right: 16px;
}
.kjZuvk {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  overflow-x: auto;
  margin: 0px;
}
.dkprfy {
  text-align: center;
  margin: 0px;
  min-width: 80px;
  border-bottom: 3px solid #752de6;
}
.dkprfy .sc-lhLRcH {
  color: #752de6;
}
.jMoMhH {
  padding: 16px 32px;
}
.jMoMhH {
  border: medium none;
  background: transparent none repeat scroll 0% 0%;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgb(75, 89, 99);
  cursor: pointer;
  padding: 16px 0px;
}
li {
  list-style: none;
    list-style-type: none;
  list-style-type: none;
}
.sc-jMtzgO {
  width: 120px;
  text-align: center;
}
.sc-jMtzgO button {
  outline: none !important;
}
</style>
